<template>
  <div>
   <v-container>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model.number="editedItem.fiscalYear"
            id="reportHeadingsTotalsTransItemFYSelect"
            class="report-headings-totals-trans-item-fy-select"
            label="Fiscal Year"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="editedItem.type"
            :items="headingsTotalsType"
            id="reportHeadingsTotalsTransItemTypeSelect"
            class="report-headings-totals-trans-item-type-select"
            label="Type"
            item-text="description"
            item-value="name"
          ></v-select>
        </v-col>
        <v-col col="3">
          <v-text-field
            v-model="editedItem.description"
            id="reportHeadingsTotalsTransItemDescriptionText"
            class="report-headings-totals-trans-item-description-text"
            label="Description"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.fromLine"
            id="reportHeadingsTotalsTransItemFromLineText"
            class="report-headings-totals-trans-item-from-line-text"
            label="From Line"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="editedItem.toLine"
            id="reportHeadingsTotalsTransItemToLineText"
            class="report-headungs-totals-trans-item-to-line-text"
            label="To Line"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'ReportHeadingsTotalsEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'reportHeadingsTotals-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
      glReportHeadingsTotalsType: (state) => state.enums.GlReportHeadingsTotalsTypeEnum,
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    headingsTotalsType() {
      if (!this.glReportHeadingsTotalsType) {
        return [];
      }
      return this.glReportHeadingsTotalsType;
    },
  },
  methods: {
  },
};
</script>
