<template>
 <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
    >
      <template v-slot:criteriaRows>
        <v-row>
          <v-col cols="3">
            <FySelect
              v-model="criteriaState.fiscalYear"
              :fyRange="fiscalYearRange"
              id="reportHeadingsTotalsCriteriaFYSelect"
              class="report-headings-totals-criteria-fy-select"
              label="By FY"
              @input="searchByCriteria"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="criteriaState.type"
              :items="headingsTotalsType"
              id="reportHeadingsTotalsCriteriaByTypeSelect"
              class="report-headings-totals-criteria-by-type-select"
              label="By Type"
              item-text="description"
              item-value="name"
              @change="searchByCriteria"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import FySelect from '../../common/FySelect.vue';
import {
  calculateFiscalYears,
} from '../../../util/shared/tmc-global';

export default {
  name: 'reportHeadingsTotalsCriteria',
  components: {
    CRUDCriteria,
    FySelect,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'transactionCriteria',
    },
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapState({
      glReportHeadingsTotalsType: (state) => state.enums.GlReportHeadingsTotalsTypeEnum,
      fiscalYearRange: (state) => {
        const registerdYears = state.glDaily.glReportHeadersTotalsTransaction.regYears || {};
        return calculateFiscalYears(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    headingsTotalsType() {
      if (!this.glReportHeadingsTotalsType) {
        return [];
      }
      return [{ name: null, description: 'All' }, ...this.glReportHeadingsTotalsType];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList() {
      this.$emit('loadCRUDList');
    },
  },
};
</script>
