<template>
  <div
    class="HeadersTotalsList">
    <ReportHeadingsTotalsCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <CRUDList
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :showDate="true"
      :showTotal="true"
      :headers="headers"
      :baseCrudKey="baseCrudKey"
      :createNewItem="newItemDialog"
      toolbarTitle="Headings & Totals"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtTransaction"
    >
      <template v-slot:[`editedItem`]="{}">
        <ReportHeadingsTotalsEditedItem
          :baseCrudKey="baseCrudKey"
        />
      </template>
    </CRUDList>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import ReportHeadingsTotalsCriteria from './ReportHeadingsTotalsCriteria.vue';
import ReportHeadingsTotalsEditedItem from './ReportHeadingsTotalsEditedItem.vue';
import { amountToNumber } from '../../../util/shared/vue-global';
import { toFiscalYear } from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Description', value: 'description' },
    { text: 'From Line', value: 'fromLine' },
    { text: 'To Line', value: 'toLine' },
  ];
  return headers;
};

export default {
  data: () => ({
    headers: [],
    baseCrudKey: 'reportHeadingsTotals',
    updatedItemArr: ['fromLine', 'toLine'],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadFYList();
  },
  components: {
    CRUDList,
    ReportHeadingsTotalsCriteria,
    ReportHeadingsTotalsEditedItem,
  },
  computed: {
    ...mapGetters(['todaysDate']),
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('glDaily/glReportHeadersTotalsTransaction', [
      'loadGLReportHeadingsTotalsTrans',
      'upsertGLReportHeadingsTotalsTrans',
      'loadFiscalRegYears',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadGLReportHeadingsTotalsTrans({
        criteria,
        baseCrudKey,
      });
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      updatedItem.fromLine = amountToNumber(updatedItem.fromLine);
      updatedItem.toLine = amountToNumber(updatedItem.toLine);
      const response = await this.upsertGLReportHeadingsTotalsTrans({
        item: updatedItem,
        baseCrudKey,
      });
      return response;
    },
    initCriteria() {
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    loadFYList() {
      this.loadFiscalRegYears();
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        type: null,
      };
      return newItem;
    },
  },
};
</script>

<style>

</style>
