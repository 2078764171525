<template>
  <div>
    <v-container >
      <ReportHeadingsTotalsList/>
    </v-container>
  </div>
</template>

<script>
import ReportHeadingsTotalsList from '../../../components/Gl/ReportHeadingsTotals/ReportHeadingsTotalsList.vue';

export default {
  name: 'ReportHeadersTotal',
  components: {
    ReportHeadingsTotalsList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
